.App {
  text-align: center;
}

// Fonts
@font-face {
  font-family: 'Bones';
  src: local('Bones'), url(./fonts/bones.ttf) format('truetype');
}

@font-face {
  font-family: 'Spooky';
  src: local('Spooky'), url(./fonts/spooky.ttf) format('truetype');
}

@font-face {
  font-family: 'Scary';
  src: local('Scary'), url(./fonts/scarylight.ttf) format('truetype');
}

@font-face {
  font-family: 'Blocked';
  src: local('Blocked'), url(./fonts/blocked.ttf) format('truetype');
}

@font-face {
  font-family: 'Scribble';
  src: local('Scribble'), url(./fonts/scribble.ttf) format('truetype');
}
