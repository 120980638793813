@import '../../styles/mixins/border.scss';


.form-datepicker {
  .datepicker-div  {
    width: 100%;
    background: transparent;
    margin: 0 0.5rem;
    border: 1px solid black;
  
    @include handdrawn-border();
  }
}