.suggest-movie-form {
  width: 80%;

  &__two-wide {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 90%;

      input {
        width: 95%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}