.menu {
  font-family: 'Blocked';
  font-size: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  border-bottom: 2px solid black;
  margin-bottom: 2rem;

  &__section {
    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    justify-content: space-evenly;
  }

  &__item {
    all: unset;
    cursor: pointer;

    transition: opacity 0.25s ease-in-out;
    &:hover {
      opacity: 50%;
    }
  }

  &__right-items {
    display: flex;
  }
}
