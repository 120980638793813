@import '../../styles/mixins/border.scss';


.form-input {
  width: 100%;
  &__input {
    width: 100%;
    background:transparent;
    padding:0.5rem 0.5rem;
    margin:0 0.5rem;
    font-family: 'Scribble';
    font-size: 1.5rem;

    box-sizing: border-box;
  
    @include handdrawn-border();
  }

  &__label {
    font-family: 'Scribble';
    font-size: 1.5rem;
    text-align: left;
    margin: 0.5rem;
  }
}